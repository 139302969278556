import { createStoreProvider, StoreBase } from "@eblsoft/react-toolkit";
import AdminDocumentApiAdapter from "Adapters/AdminAdapter/AdminDocumentApiAdapter";
import AdminSettingsApiAdapter from "Adapters/AdminAdapter/AdminSettingsApiAdapter";
import { action, observable, runInAction } from "mobx";
import ITfaInitData from "Models/ITfaInitData";
import AuthStore from "Stores/AuthStore";
import { SettingsKeys } from "./SettingsKeys";
import NotificationStore from "Stores/NotificationStore";

export default class SettingsStore extends StoreBase {

    @observable.ref
    public tfaInitData: ITfaInitData | null = null;

    @observable.ref
    public defaultCardPermanentCancellationFee: number = 0;

    @observable.ref
    public hasTfa = false;

    @action.bound
    public setDefaultCardPermanentCancellationFee(value: number | null) { this.defaultCardPermanentCancellationFee = value ?? 0; }

    public readonly vLoadAsync = this.async(async () => {
        if (AuthStore.user?.hasRole("DocRev")) {
            var hasTfa = await AdminDocumentApiAdapter.hasTfaAsync();
            runInAction(() => {
                this.hasTfa = hasTfa;
            });
        }

        if (AuthStore.user?.hasRole("Admin")) {
            const defaultCardPermanentCancellationFee = await AdminSettingsApiAdapter.getAsync(SettingsKeys.DefaultCardPermanentCancellationFeeSettingsKey);
            runInAction(() => {
                this.defaultCardPermanentCancellationFee = Number(defaultCardPermanentCancellationFee);
            });
        }

    });

    public readonly saveDefaultCardPermanentCancellationFeeAsync = this.async(async () => {
        const res = await AdminSettingsApiAdapter.updateAsync(SettingsKeys.DefaultCardPermanentCancellationFeeSettingsKey, this.defaultCardPermanentCancellationFee.toString());

        if (res) {
            NotificationStore.success("Sikeres mentés");
        }
    });

    public readonly createTfaAsync = this.async(async () => {
        const res = await AdminDocumentApiAdapter.createTfaAsync();
        runInAction(() => {
            this.tfaInitData = res;
        });
    });

    // public readonly getTfaAsync = this.async(async () => {
    //     const res = await AdminDocumentApiAdapter.createTfaAsync();
    //     runInAction(() => {
    //         this.tfaInitData = res;
    //     });
    // });

}

const { ContextComponent: SettingsStoreContext, StoreProvider: SettingsStoreProvider, useStore: useSettingsStore, withStore: withSettingsStore } = createStoreProvider(() => new SettingsStore());

export {
    SettingsStoreContext,
    SettingsStoreProvider,
    useSettingsStore,
    withSettingsStore
};