import Address from "Models/Address";
import { Partner, PartnerWithEmailPreferences } from "Models/Partner";
import PhoneNumber from "Models/PhoneNumber";
import TaxiDriver from "Models/TaxiDriver";
import Voucher from "Models/Voucher";
import { IAccountedRideDto, IAccountedRideInfoDto, IFailedRideDto, IAddress, IInvoiceListItemDto, IInvoiceSummaryDetailsDto, IInvoiceSummaryForPartnerListItemDto, IConsentListFilterDto, IPartnerDto, IPhoneNumber, ITaxiDriverDto, ITaxiVoucher, ITaxNumber, IFailedRideDetailsForReviewDto, ICustomer, IPartnerListItemDto, ITaxiDriverListItemDto, ITaxiCardOrderListItemDto, ITaxiVoucherListItemDto, IPhoneNumberListItemDto, ITaxiCardListItemDto, IBusinessPartner, IGetPartnerInfoResponse, IInvoiceListFilterDto, ITaxiDriverListFilterDto, IInvoiceListItemDetailDto, ICreatedTaxiCardListItemDto, IRideListFilterDto, IRideListItemDto, IPollListFilterDto, IPollListItemDto, IPollDto, IVoteListItemDto, IVoteListFilterDto, IPollDistributionsDto, ITaxiCardProhibitionEventListFilterDto, ITaxiCardProhibitionEventListItemDto, ISimpleDocumentListFilterDto, ISimpleDocumentListItemDto, ISimpleDocumentDto, ITrafficLicenseListFilterDto, ITrafficLicenseListItemDto, ITrafficLicenseDto, IVehicleConditionListFilterDto, IVehicleConditionListItemDto, IVehicleConditionDto, IAdminDocumentListFilterDto, IAdminDocumentListItemDto, IAdminConsentListItemDto, IAuditEventListItemDto, IAuditEventListFilterDto, ICommonFileListItemDto } from "Adapters/Api/Api.g";
import TaxNumber from "Models/TaxNumber";
import { isNullOrUndefined } from "@eblsoft/react-toolkit";
import AccountedRide from "Models/AccountedRide";
import moment from "moment";
import AccountedRideDetails from "Models/AccountedRideDetails";
import InvoiceForPartnerListItem from "Models/InvoiceForPartnerListItem";
import InvoiceSummaryForPartnerListItem from "Models/InvoiceSummaryForPartnerListItem";
import InvoiceSummaryDetails from "Models/InvoiceSummaryDetails";
import FailedRide from "Models/FailedRide";
import FailedRideDetailsForReview from "Models/FailedRideDetailsForReview";
import Customer from "Models/Customer";
import TaxiCardOrderListItem from "Models/TaxiCardOrderListItem";
import PartnerListItem from "Models/PartnerListItem";
import TaxiDriverListItem from "Models/TaxiDriverListItem";
import VoucherListItem from "Models/VoucherListItem";
import PhoneNumberListItem from "Models/PhoneNumberListItem";
import TaxiCardListItem from "Models/TaxiCardListItem";
import BusinessPartner from "Models/BusinessPartner";
import InvoiceFilter from "Models/Filters/InvoiceFilter";
import TaxiDriverFilter from "Models/Filters/TaxiDriverFilter";
import InvoiceDetailForPartnerListItem from "Models/InvoiceDetailForPartnerListItem";
import CreatedTaxiCardListItem from "Models/CreatedTaxiCardListItem";
import RideFilter from "Models/Filters/RideFilter";
import RideLitsItem from "Models/RideListItem";
import PollFilter from "Models/Filters/PollFilter";
import PollListItem from "Models/PollListItem";
import Poll from "Models/Poll";
import Answer from "Models/Answer";
import VoteListItem from "Models/VoteListItem";
import VoteFilter from "Models/Filters/VoteFilter";
import Distribution from "Models/Distribution";
import PollDistributions from "Models/PollDistribution";
import TaxiCardProhibitionEventFilter from "Models/Filters/TaxiCardProhibitionEventFilter";
import TaxiCardProhibitionEventListItem from "Models/TaxiCardProhibitionEventListItem";
import SimpleDocumentFilter from "Models/Filters/SimpleDocumentFilter";
import SimpleDocumentListItem from "Models/SimpleDocumentListItem";
import SimpleDocument from "Models/SimpleDocument";
import TrafficLicenseFilter from "Models/Filters/TrafficLicenseFilter";
import TrafficLicenseListItem from "Models/TrafficLicenseListItem";
import TrafficLicense from "Models/TrafficLicense";
import VehicleConditionFilter from "Models/Filters/VehicleConditionFilter";
import VehicleConditionListItem from "Models/VehicleConditionListItem";
import VehicleCondition from "Models/VehicleCondition";
import AdminDocumentFilter from "Models/Filters/AdminDocumentFilter";
import { AdminDocumentListItem, IDocumentDataInit } from "Models/AdminDocumentListItem";
import ConsentFilter from "Models/Filters/ConsentFilter";
import AuditEventFilter from "Models/Filters/AuditEventFilter";
import ConsentListItem from "Models/ConsentListItem";
import AuditEventListItem from "Models/AuditEventListItem";
import CommonFileListItem from "Models/CommonFileListItem";

export function mapAccountedRideDetailToDomainModel(dto: IAccountedRideDto) {
    return new AccountedRideDetails(
        dto.id,
        dto.publicIdentifier,
        dto.voucherType,
        dto.voucherInfo,
        moment(dto.createdAt),
        dto.costPlacement ?? null,
        parseInt(dto.grossPrice, 10),
        mapTaxiDriverDtoToDomainModel(dto.taxiDriver),
        mapPartnerDtoToDomainModel(dto.partner)
    );
}

export function mapAccountedRideListItemToDomainModel(dto: IAccountedRideInfoDto) {
    return new AccountedRide(
        dto.id,
        dto.publicIdentifier,
        dto.rideIdentifier ?? "",
        dto.voucherType,
        dto.voucherInfo,
        moment(dto.createdAt),
        dto.driverName,
        dto.driverBpid,
        dto.costPlacementIdentifier,
        parseInt(dto.grossPrice, 10),
        dto.rideAdditionalInfo ?? "",
        dto.customerName,
        dto.customerBpid
    );
}

export function mapFailedRideToDomainModel(dto: IFailedRideDto) {
    return new FailedRide(
        dto.id,
        dto.publicIdentifier,
        dto.rideIdentifier ?? "",
        dto.driverName,
        dto.driverBpid,
        dto.taxiDriverName ?? "",
        parseInt(dto.grossPrice, 10),
        moment(dto.createdAt),
        dto.deletedAt ? moment(dto.deletedAt) : null,
        dto.isApproved,
        dto.reviewedAt ? moment(dto.reviewedAt) : null
    );
}

export function mapFailedRideDetailsForReviewToDomainModel(dto: IFailedRideDetailsForReviewDto) {
    return new FailedRideDetailsForReview(
        dto.id,
        dto.publicIdentifier,
        dto.rideIdentifier ?? "",
        dto.driverName,
        dto.driverBpid,
        dto.taxiDriverName ?? "",
        parseInt(dto.grossPrice, 10),
        moment(dto.createdAt),
        dto.deletedAt ? moment(dto.deletedAt) : null,
        dto.isApproved,
        dto.reviewedAt ? moment(dto.reviewedAt) : null,
        dto.rideAdditionalInfo,
        dto.driverComment,
        dto.reviewerComment,
        dto.partnerIdFromRide,
        dto.customerFromRide ? mapCustomerToDomainModel(dto.customerFromRide) : null,
    );
}

export function mapCustomerToDomainModel(dto: ICustomer) {
    return new Customer(
        dto.name,
        dto.isPrivatePerson,
        mapTaxNumberDtoToDomainModel(dto.taxNumber)!,
        mapTaxNumberDtoToDomainModel(dto.groupMemberTaxNumber),
        mapAddressDtoToDomainModel(dto.address));
}

export function mapPartnerDtoToDomainModel(dto: IPartnerDto) {
    return new Partner(
        dto.id,
        dto.bpid,
        dto.bpName,
        mapTaxNumberDtoToDomainModel(dto.taxNumber)!,
        mapTaxNumberDtoToDomainModel(dto.groupMemberTaxNumber),
        mapAddressDtoToDomainModel(dto.address),
        dto.mailingAddress ? mapAddressDtoToDomainModel(dto.mailingAddress) : null,
        dto.name ? dto.name : null,
        dto.isPrivatePerson,
        isNullOrUndefined(dto.cardPermanentCancellationFee) ? null : dto.cardPermanentCancellationFee);
}

export function mapPartnerWithEmailPreferencesDtoToDomainModel(dto: IGetPartnerInfoResponse) {
    return new PartnerWithEmailPreferences(
        dto.partner.id,
        dto.partner.bpid,
        dto.partner.bpName,
        mapTaxNumberDtoToDomainModel(dto.partner.taxNumber)!,
        mapTaxNumberDtoToDomainModel(dto.partner.groupMemberTaxNumber),
        mapAddressDtoToDomainModel(dto.partner.address),
        dto.partner.mailingAddress ? mapAddressDtoToDomainModel(dto.partner.mailingAddress) : null,
        dto.invoiceSummaryEmail,
        dto.eTaxiContactEmail,
        dto.partner.name ? dto.partner.name : null,
        dto.partner.isPrivatePerson,
        dto.includeRideDataInInvoiceSummary,
        dto.partner.cardPermanentCancellationFee);
}

export function mapPartnerListItemDtoToDomainModel(dto: IPartnerListItemDto) {
    return new PartnerListItem(
        dto.id,
        dto.bpid,
        dto.bpName,
        mapTaxNumberDtoToDomainModel(dto.taxNumber)!,
        dto.orderAbleTaxNumber,
        mapTaxNumberDtoToDomainModel(dto.groupMemberTaxNumber),
        mapAddressDtoToDomainModel(dto.address),
        dto.mailingAddress ? mapAddressDtoToDomainModel(dto.mailingAddress) : null,
        dto.name,
        dto.isPrivatePerson);
}

export function mapTaxiDriverDtoToDomainModel(dto: ITaxiDriverDto) {
    return new TaxiDriver(
        dto.id,
        dto.bpid,
        dto.name,
        mapTaxNumberDtoToDomainModel(dto.taxNumber)!,
        mapAddressDtoToDomainModel(dto.address),
        dto.isActive);
}

export function mapTaxiDriverListItemDtoToDomainModel(dto: ITaxiDriverListItemDto) {
    return new TaxiDriverListItem(
        dto.id,
        dto.bpid,
        dto.driverName,
        mapTaxNumberDtoToDomainModel(dto.taxNumber)!,
        dto.orderAbleTaxNumber,
        dto.selfEmployedIdentifier,
        dto.name,
        mapAddressDtoToDomainModel(dto.address),
        dto.mailingAddress ? mapAddressDtoToDomainModel(dto.mailingAddress) : null,
        dto.enteredOn ? moment(dto.enteredOn) : null,
        dto.leavedOn ? moment(dto.leavedOn) : null,
        dto.email,
        dto.isCooperativeMember);
}

export function mapAddressDtoToDomainModel(dto: IAddress) {
    return new Address(
        dto.countryCode,
        dto.region,
        dto.zipCode,
        dto.settlement,
        dto.addressLine)
}

export function mapTaxNumberDtoToDomainModel(dto?: ITaxNumber) {
    if (!dto) return null;
    return new TaxNumber(dto.identifier, dto.vatCode, dto.countyCode);
}

export function mapTaxiCardListItemDtoToDomainModel(dto: ITaxiCardListItemDto) {
    return new TaxiCardListItem(
        dto.id,
        dto.partnerId,
        dto.identifier,
        dto.pin ?? "",
        dto.isPhysical,
        dto.isActive,
        dto.costPlacementType,
        dto.costPlacement ?? "",
        dto.isPermanentlyCancelled,
        dto.ownerName,
        dto.customerName,
        dto.customerBpid,
        dto.prohibitionReason,
        dto.prohibitedBy,
        moment(dto.orderedAt),
        dto.preMadeCardIndex);
}

export function mapCreatedTaxiCardListItemDtoToDomainModel(dto: ICreatedTaxiCardListItemDto) {
    return new CreatedTaxiCardListItem(dto.id, dto.partnerId, dto.identifier, dto.pin ?? "", dto.isPhysical, dto.isActive, dto.costPlacementType, dto.costPlacement ?? "", dto.ownerName);
}

export function mapPhoneNumberDtoToDomainModel(dto: IPhoneNumber) {
    return new PhoneNumber(dto.id, dto.number, dto.isActive, dto.costPlacementType, dto.costPlacement ?? "", dto.pin, dto.name ?? "");
}

export function mapPhoneNumberListItemDtoToDomainModel(dto: IPhoneNumberListItemDto) {
    return new PhoneNumberListItem(dto.id, dto.number, dto.isActive, dto.costPlacementType, dto.costPlacement ?? "", dto.pin, dto.name ?? "", dto.customerName, dto.customerBpid);
}

export function mapVoucherDtoToDomainModel(dto: ITaxiVoucher) {
    return new Voucher(
        dto.id,
        dto.identifier,
        isNullOrUndefined(dto.requestDate) ? null : moment(dto.requestDate),
        isNullOrUndefined(dto.validity) ? null : moment(dto.validity),
        dto.cost,
        dto.costPlacement ?? "",
        dto.costPlacementType,
        isNullOrUndefined(dto.prohibitedAt) ? null : moment(dto.prohibitedAt),
        dto.accountedRideId);
}


export function mapVoucherListItemDtoToDomainModel(dto: ITaxiVoucherListItemDto) {
    return new VoucherListItem(
        dto.id,
        dto.identifier,
        isNullOrUndefined(dto.requestDate) ? null : moment(dto.requestDate),
        isNullOrUndefined(dto.validity) ? null : moment(dto.validity),
        dto.cost,
        dto.costPlacement ?? "",
        dto.costPlacementType,
        isNullOrUndefined(dto.prohibitedAt) ? null : moment(dto.prohibitedAt),
        dto.accountedRideId,
        dto.customerName ?? "",
        dto.customerBpid ?? "");
}

export function mapTaxiCardOrderListItemDtoToDomainModel(dto: ITaxiCardOrderListItemDto) {
    return new TaxiCardOrderListItem(
        dto.id,
        moment(dto.createdAt),
        dto.status,
        dto.numberOfCards,
        dto.sellerName,
        dto.sellerBpid,
        isNullOrUndefined(dto.deliveryAddress) ? null : mapAddressDtoToDomainModel(dto.deliveryAddress),
        dto.requesterName,
        dto.requesterPhoneNumber,
        dto.referenceNumber,
        dto.preMadeCardFrom,
        dto.preMadeCardTo);
}

export function mapInvoiceListFilterToDto(filter: InvoiceFilter): IInvoiceListFilterDto {
    return {
        accountedRidePublicIdentifier: filter.accountedRidePublicIdentifier,
        invoiceNumber: filter.invoiceNumber,
        rideExternalIdentifier: filter.rideExternalIdentifier,
        seller: filter.seller,
        customer: filter.customer,
        createdOnFrom: filter.createdOnFrom?.format("YYYY-MM-DD") ?? null,
        createdOnTo: filter.createdOnTo?.format("YYYY-MM-DD") ?? null,
        accountedOnFrom: filter.accountedOnFrom?.toISOString() ?? null,
        accountedOnTo: filter.accountedOnTo?.toISOString() ?? null,
        costCenter: filter.costCenter,
        grossCostFrom: filter.grossCostFrom?.toString() ?? null,
        grossCostTo: filter.grossCostTo?.toString() ?? null,
        voucherType: filter.voucherType,
        voucherInfo: filter.voucherInfo,
        startSettlement: filter.startSettlement,
        startZipCode: filter.startZipCode,
        startAddressLine: filter.startAddressLine,
        rideCustomerName: filter.rideCustomerName,
        fulfilledOnFrom: filter.fulfilledOnFrom?.format("YYYY-MM-DD") ?? null,
        fulfilledOnTo: filter.fulfilledOnTo?.format("YYYY-MM-DD") ?? null,
        partnerInvoiceSummaryIdentifier: filter.partnerInvoiceSummaryIdentifier,
        statuses: filter.statuses
    };
}

export function applyInvoiceListFilterDtoTo(filter: IInvoiceListFilterDto, target: InvoiceFilter): void {
    target.accountedRidePublicIdentifier = filter.accountedRidePublicIdentifier ?? "";
    target.invoiceNumber = filter.invoiceNumber ?? "";
    target.rideExternalIdentifier = filter.rideExternalIdentifier ?? "";
    target.seller = filter.seller ?? "";
    target.customer = filter.customer ?? "";
    target.createdOnFrom = filter.createdOnFrom ? moment(filter.createdOnFrom) : null;
    target.createdOnTo = filter.createdOnTo ? moment(filter.createdOnTo) : null;
    target.accountedOnFrom = filter.accountedOnFrom ? moment(filter.accountedOnFrom) : null;
    target.accountedOnTo = filter.accountedOnTo ? moment(filter.accountedOnTo) : null;
    target.costCenter = filter.costCenter ?? "";
    target.grossCostFrom = filter.grossCostFrom ? parseInt(filter.grossCostFrom) : null;
    target.grossCostTo = filter.grossCostTo ? parseInt(filter.grossCostTo) : null;
    target.voucherType = filter.voucherType;
    target.voucherInfo = filter.voucherInfo ?? "";
    target.startSettlement = filter.startSettlement ?? "";
    target.startZipCode = filter.startZipCode ?? "";
    target.startAddressLine = filter.startAddressLine ?? "";
    target.rideCustomerName = filter.rideCustomerName ?? "";
    target.fulfilledOnFrom = filter.fulfilledOnFrom ? moment(filter.fulfilledOnFrom) : null;
    target.fulfilledOnTo = filter.fulfilledOnTo ? moment(filter.fulfilledOnTo) : null;
    target.partnerInvoiceSummaryIdentifier = filter.partnerInvoiceSummaryIdentifier ?? "";
    target.statuses = filter.statuses ? filter.statuses : [];
}

export function applyDocumentListFilterDtoTo(filter: ISimpleDocumentListFilterDto, target: SimpleDocumentFilter): void {
    target.identifier = filter.identifier ?? "";
    target.partnerBpid = filter.bpid ?? "";
    target.partnerName = filter.name ?? "";
    target.status = filter.status;
    target.validToFrom = filter.validToFrom ? moment(filter.validToFrom) : null;
    target.validToTo = filter.validToTo ? moment(filter.validToTo) : null;
}

export function applyAdminDocumentListFilterDtoTo(filter: IAdminDocumentListFilterDto, target: AdminDocumentFilter): void {
    target.bpid = filter.bpid ?? "";
    target.name = filter.name ?? "";
    target.documentStatuses = filter.statuses ?? [];
}

export function mapPollListFilterToDto(filter: PollFilter): IPollListFilterDto {
    return {
        name: filter.name,
        endOfVoteDateFrom: filter.endOfVoteDateFrom?.format("YYYY-MM-DD") ?? null,
        endOfVoteDateTo: filter.endOfVoteDateTo?.format("YYYY-MM-DD") ?? null,
    };
}

export function mapTaxiCardProhibitionEventListFilterToDto(filter: TaxiCardProhibitionEventFilter): ITaxiCardProhibitionEventListFilterDto {
    return {
        partnerName: filter.partnerName,
        partnerBpid: filter.partnerBpid,
        identifier: filter.identifier,
        prohibitionOccuredAtFrom: filter.prohibitionOccuredAtFrom?.toISOString() ?? null,
        prohibitionOccuredAtTo: filter.prohibitionOccuredAtTo?.toISOString() ?? null,
        prohibitedByUserName: filter.prohibitedByUserName,
        prohibitedByName: filter.prohibitedByName,
        prohibitionReason: filter.prohibitionReason,
        status: filter.status,
    };
}

export function mapTaxiCardProhibitionEventListItemDtoToDomainModel(dto: ITaxiCardProhibitionEventListItemDto) {
    return new TaxiCardProhibitionEventListItem(
        dto.id,
        dto.status,
        dto.identifier,
        dto.partnerName,
        dto.partnerBpid,
        moment(dto.occuredAt),
        dto.prohibitedByUserName,
        dto.prohibitedByName,
        dto.prohibitionReason);
}

export function mapSimpleDocumentListFilterToDto(filter: SimpleDocumentFilter): ISimpleDocumentListFilterDto {
    return {
        name: filter.partnerName,
        bpid: filter.partnerBpid,
        identifier: filter.identifier,
        validToFrom: filter.validToFrom?.format("YYYY-MM-DD") ?? null,
        validToTo: filter.validToTo?.format("YYYY-MM-DD") ?? null,
        status: filter.status,
    };
}

export function mapAdminDocumentListFilterToDto(filter: AdminDocumentFilter): IAdminDocumentListFilterDto {
    return {
        name: filter.name,
        driverName: filter.driverName,
        bpid: filter.bpid,
        licensePlateNumber: filter.licensePlateNumber,
        statuses: filter.documentStatuses,
    };
}

export function mapAdminConsentListFilterToDto(filter: ConsentFilter): IConsentListFilterDto {
    return {
        consentSummary: filter.consentSummary,
        createdAtFrom: filter.createdAtFrom?.toISOString() ?? null,
        createdAtTo: filter.createdAtTo?.toISOString() ?? null,
        driverNameOrBpid: filter.driverNameOrBpid,
        includeRevoked: false,
        statementType: filter.statementType
    };
}

export function mapAdminAuditEventListFilterToDto(filter: AuditEventFilter): IAuditEventListFilterDto {
    return {
        occuredAtFrom: filter.occuredAtFrom?.toISOString() ?? null,
        occuredAtTo: filter.occuredAtTo?.toISOString() ?? null,
        eventTypes: filter.eventTypes,
        user: filter.user
    };
}

export function mapVehicleConditionListFilterToDto(filter: VehicleConditionFilter): IVehicleConditionListFilterDto {
    return {
        name: filter.partnerName,
        bpid: filter.partnerBpid,
        createdAtFrom: filter.createdAtFrom?.toISOString() ?? null,
        createdAtTo: filter.createdAtTo?.toISOString() ?? null,
        type: filter.type,
        status: filter.status,
    };
}

export function mapVehicleConditionListItemDtoToDomainModel(dto: IVehicleConditionListItemDto) {
    return new VehicleConditionListItem(
        dto.id,
        dto.bpid,
        dto.name,
        moment(dto.createdAt),
        dto.status,
        dto.type);
}

export function mapVehicleConditionDtoToDomainModel(dto: IVehicleConditionDto) {
    return new VehicleCondition(
        dto.id,
        moment(dto.createdAt),
        dto.status,
        dto.type,
        dto.images,
        dto.description);
}

export function mapTrafficLicenseListFilterToDto(filter: TrafficLicenseFilter): ITrafficLicenseListFilterDto {
    return {
        name: filter.partnerName,
        bpid: filter.partnerBpid,
        identifier: filter.identifier,
        licensePlateNumber: filter.licensePlateNumber,
        status: filter.status,
    };
}

export function mapSimpleDocumentListItemDtoToDomainModel(dto: ISimpleDocumentListItemDto) {
    return new SimpleDocumentListItem(
        dto.id,
        dto.bpid,
        dto.name,
        dto.identifier,
        isNullOrUndefined(dto.validTo) ? null : moment(dto.validTo),
        dto.status,
        isNullOrUndefined(dto.lastReviewedAt) ? null : moment(dto.lastReviewedAt),
        isNullOrUndefined(dto.lastUpdatedAt) ? null : moment(dto.lastUpdatedAt));
}

export function mapAdminDocumentListItemDtoToDomainModel(dto: IAdminDocumentListItemDto) {
    return new AdminDocumentListItem(
        dto.bpid,
        dto.name,
        dto.driverName,
        dto.licensePlateNumber,
        {
            driverLicence: mapAdminDocument(dto, "driverLicence"),
            taxiDriverLicence: mapAdminDocument(dto, "taxiDriverLicence"),
            taxiStationUsageAgreement: mapAdminDocument(dto, "taxiStationUsageAgreement"),
            taximeterCertificate: mapAdminDocument(dto, "taximeterCertificate"),
            trafficLicense: mapAdminDocument(dto, "trafficLicense"),
            transportingLicence: mapAdminDocument(dto, "transportingLicence"),
            transportingLicenceSummary: mapAdminDocument(dto, "transportingLicenceSummary"),
            vehicleCertificate: mapAdminDocument(dto, "vehicleCertificate"),
        }
    );
}

export function mapConsentListItemDtoToDomainModel(dto: IAdminConsentListItemDto) {
    return new ConsentListItem(
        dto.consentId,
        dto.statementType,
        moment(dto.createdAt),
        isNullOrUndefined(dto.revokedAt) ? null : moment(dto.revokedAt),
        dto.driverName,
        dto.driverBpid,
        dto.summary
    );
}

export function mapAuditEventListItemDtoToDomainModel(dto: IAuditEventListItemDto) {
    return new AuditEventListItem(
        dto.user,
        dto.type,
        moment(dto.occuredAt),
        dto.technicalInfo,
        dto.referencedSimpleDocumentType,
        dto.referencedTaxiDriverBpid,
        dto.referencedTaxiDriverName,
        dto.referencedPartnerBpid,
        dto.referencedPartnerName,
        dto.referencedAccountedRideIdentifier,
        dto.referencedInvoiceNumber
    );
}

function mapAdminDocument(dto: any, documentKey: string): IDocumentDataInit {
    return {
        documentId: dto[`${documentKey}DocumentId`],
        status: dto[`${documentKey}Status`],
        validTo: isNullOrUndefined(dto[`${documentKey}ValidTo`]) ? null : moment(dto[`${documentKey}ValidTo`]),
        updatedAt: isNullOrUndefined(dto[`${documentKey}UpdatedAt`]) ? null : moment(dto[`${documentKey}UpdatedAt`]),
    };
}

export function mapTrafficLicenseListItemDtoToDomainModel(dto: ITrafficLicenseListItemDto) {
    return new TrafficLicenseListItem(
        dto.id,
        dto.bpid,
        dto.name,
        dto.identifier,
        isNullOrUndefined(dto.validTo) ? null : moment(dto.validTo),
        dto.status,
        isNullOrUndefined(dto.lastReviewedAt) ? null : moment(dto.lastReviewedAt),
        isNullOrUndefined(dto.lastUpdatedAt) ? null : moment(dto.lastUpdatedAt));
}

export function mapTrafficLicenseDtoToDomainModel(dto: ITrafficLicenseDto) {
    return new TrafficLicense(
        dto.id,
        dto.identifier,
        dto.licensePlateNumber,
        dto.makeOfCar,
        dto.typeOfCar,
        isNullOrUndefined(dto.validFrom) ? null : moment(dto.validFrom),
        isNullOrUndefined(dto.validTo) ? null : moment(dto.validTo),
        isNullOrUndefined(dto.lastConfirmedValidTo) ? null : moment(dto.lastConfirmedValidTo),
        dto.status,
        dto.images);
}

export function mapSimpleDocumentDtoToDomainModel(dto: ISimpleDocumentDto) {
    return new SimpleDocument(
        dto.id,
        dto.identifier,
        isNullOrUndefined(dto.validTo) ? null : moment(dto.validTo),
        dto.status,
        dto.images,
        isNullOrUndefined(dto.lastConfirmedValidTo) ? null : moment(dto.lastConfirmedValidTo));
}

export function mapVoteListFilterToDto(filter: VoteFilter): IVoteListFilterDto {
    return {
        name: filter.name,
        bpId: filter.bpId,
        answerNumber: filter.answerNumber,
        anseweredAtFrom: filter.anseweredAtFrom?.format("YYYY-MM-DD") ?? null,
        anseweredAtTo: filter.anseweredAtTo?.format("YYYY-MM-DD") ?? null,
        onlyCooperativeMember: filter.onlyShowCooperativeMember
    };
}


export function mapPollListtItemDtoToDomainModel(dto: IPollListItemDto): PollListItem {
    return new PollListItem(
        dto.id,
        dto.name,
        isNullOrUndefined(dto.endOfVoteDate) ? null : moment(dto.endOfVoteDate),
        isNullOrUndefined(dto.startedAt) ? null : moment(dto.startedAt)
    );
}

export function mapCommonFileListtItemDtoToDomainModel(dto: ICommonFileListItemDto): CommonFileListItem {
    return new CommonFileListItem(
        dto.id,
        dto.name,
        dto.createdByName,
        moment(dto.createdAt),
        dto.category,
    );
}

export function mapvoteListItemDtoToDomainModel(dto: IVoteListItemDto): VoteListItem {
    return new VoteListItem(
        dto.id,
        dto.bpid,
        dto.name,
        isNullOrUndefined(dto.answeredAt) ? null : moment(dto.answeredAt),
        dto.answerNumber,
        dto.isCooperativeMember,
        dto.customAnswer
    );
}

export function mapPoll(dto: IPollDto): Poll {
    return new Poll(
        dto.id,
        dto.question,
        dto.name,
        isNullOrUndefined(dto.endOfVoteDate) ? null : moment(dto.endOfVoteDate),
        dto.answers!.map(a => new Answer(a.number, a.text)),
        isNullOrUndefined(dto.startedAt) ? null : moment(dto.startedAt),
        dto.targetAudience,
        dto.isCustomAnswerEnabled,
    );
}

export function mapPollDistributions(dto: IPollDistributionsDto): PollDistributions {
    return new PollDistributions(
        dto.pollDistributionDto.map(a => new Distribution(a.name, a.percentage, a.quantity)),
        dto.answerDistributionDto.map(a => new Distribution(a.name, a.percentage, a.quantity)),
        isNullOrUndefined(dto.cooperativeMemberAnswerDistributionDto) ? null : dto.cooperativeMemberAnswerDistributionDto!.map(a => new Distribution(a.name, a.percentage, a.quantity)),
        isNullOrUndefined(dto.pollCooperativeMemberDistributionDto) ? null : dto.pollCooperativeMemberDistributionDto!.map(a => new Distribution(a.name, a.percentage, a.quantity))
    );
}

export function mapRideListFilterToDto(filter: RideFilter): IRideListFilterDto {
    return {
        phoneNumber: filter.phoneNumber,
        name: filter.name,
        externalId: filter.externalId,
        addressLine: filter.addressLine,
        rideFrom: filter.rideFrom?.toISOString() ?? null,
        rideTo: filter.rideTo?.toISOString() ?? null,
        zipCode: filter.zipCode,
        settlement: filter.settlement
    };
}

export function mapRideListItemDtoToDomain(dto: IRideListItemDto): RideLitsItem {
    return new RideLitsItem(
        dto.rideId,
        dto.externalId,
        moment(dto.createdAt),
        dto.isAutoAccounted,
        dto.phoneNumber ?? null,
        dto.name,
        mapAddressDtoToDomainModel(dto.address),
        dto.additionalInfo);
}

export function mapTaxiDriverListFilterToDto(filter: TaxiDriverFilter): ITaxiDriverListFilterDto {
    return {
        name: filter.name,
        driverName: filter.driverName,
        bpid: filter.bpId,
        taxNumber: filter.taxNumber,
        showLeavedDrivers: filter.showLeavedDrivers,
        enteredOnFrom: filter.enteredOnFrom?.format("YYYY-MM-DD") ?? null,
        enteredOnTo: filter.enteredOnTo?.format("YYYY-MM-DD") ?? null,
        leavedOnFrom: filter.leavedOnFrom?.format("YYYY-MM-DD") ?? null,
        leavedOnTo: filter.leavedOnTo?.format("YYYY-MM-DD") ?? null,
        zipCode: filter.zipCode,
        settlement: filter.settlement,
        addressLine: filter.addressLine,
        addressType: filter.addressType,
        selfEmployedIdentifier: filter.selfEmployedIdentifier,
        isCooperativeMember: filter.isCooperativeMember,
    };
}

export function mapInvoiceForPartnerListItemDtoToDomainModel(dto: IInvoiceListItemDto) {
    return new InvoiceForPartnerListItem(
        dto.id,
        dto.invoiceType,
        dto.referencedInvoiceNumber,
        dto.accountedRideId,
        dto.accountedRidePublicIdentifier,
        dto.rideExternalIdentifier,
        dto.sellerName,
        dto.sellerBpid,
        dto.invoiceNumber,
        parseInt(dto.netPrice),
        parseInt(dto.vatPercentage),
        dto.vatExcemptionType,
        parseInt(dto.grossPrice),
        moment(dto.createdOn),
        moment(dto.fulfilledOn),
        dto.status,
        dto.costCenter,
        dto.customerName,
        dto.customerBpid,
        moment(dto.accountedAt),
        dto.voucherType,
        dto.voucherInfo,
        dto.partnerInvoiceSummaryIdentifier,
        dto.startAddress ? mapAddressDtoToDomainModel(dto.startAddress) : null,
        isNullOrUndefined(dto.intermediateAddresses) ? null : dto.intermediateAddresses!.map(a => mapAddressDtoToDomainModel(a)),
        dto.finalAddress ? mapAddressDtoToDomainModel(dto.finalAddress) : null,
        dto.rideAdditionalInfo,
        dto.rideCustomerName
    );
}

export function mapInvoiceDetailForPartnerListItemDtoToDomainModel(dto: IInvoiceListItemDetailDto) {
    return new InvoiceDetailForPartnerListItem(
        dto.id,
        dto.invoiceType,
        dto.accountedRideId,
        dto.accountedRidePublicIdentifier,
        dto.rideExternalIdentifier,
        dto.invoiceNumber,
        dto.sellerName,
        dto.sellerBpid,
        dto.sellerAddress ? mapAddressDtoToDomainModel(dto.sellerAddress) : null,
        mapTaxNumberDtoToDomainModel(dto.sellerTaxNumber),
        mapPartnerDtoToDomainModel(dto.customer),
        parseInt(dto.netPrice as unknown as string),
        parseInt(dto.grossPrice),
        moment(dto.createdOn),
        moment(dto.fulfilledOn),
        dto.costCenter,
        dto.voucherType,
        dto.voucherInfo,
        moment(dto.accountedAt),
        dto.startAddress ? mapAddressDtoToDomainModel(dto.startAddress) : null,
        dto.finalAddress ? mapAddressDtoToDomainModel(dto.finalAddress) : null,
        dto.shouldUpdateFinalAddress,
        dto.intermediateAddresses ? dto.intermediateAddresses.map(mapAddressDtoToDomainModel) : null,
        dto.shouldUpdateIntermediateAddresses,
        dto.rideAdditionalInfo,
        dto.rideCustomerName,
        dto.latestModifierInvoiceId,
        dto.latestModifierInvoiceNumber
    );
}


export function mapInvoiceSummaryForPartnerListItemDtoToDomainModel(dto: IInvoiceSummaryForPartnerListItemDto) {
    return new InvoiceSummaryForPartnerListItem(
        dto.id,
        dto.identifier,
        moment(dto.createdOn),
        moment(dto.fromDate),
        moment(dto.toDate),
    );
}

export function mapInvoiceSummaryDetailsDtoToDomainModel(dto: IInvoiceSummaryDetailsDto) {
    return new InvoiceSummaryDetails(
        dto.id,
        dto.identifier,
        moment(dto.createdOn),
        moment(dto.fromDate),
        moment(dto.toDate),
        dto.invoices.map(mapInvoiceForPartnerListItemDtoToDomainModel)
    );
}

export function mapBusinessPartnerDtoToDomainModel(dto: IBusinessPartner) {
    return new BusinessPartner(
        dto.id,
        dto.bpid,
        dto.name,
        mapTaxNumberDtoToDomainModel(dto.taxNumber)!,
        mapTaxNumberDtoToDomainModel(dto.groupMemberTaxNumber),
        mapAddressDtoToDomainModel(dto.address),
        dto.isSelfEmployed,
        dto.isSmallBusiness,
        dto.isPrivatePerson
    );
}